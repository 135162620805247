import getters from './getters';

describe('encoder getters', () => {
  describe('encoderSetting', () => {

  });

  describe('availableEncoders', () => {
    it.skip('should return all encoders return from cgi', () => {
      const state = {
        0: { name: 'aaaa' },
        3: { name: 'dddd' },
        9: { name: 'Pikachu' }
      };
      expect(getters.availableEncoders(state)).toEqual([
        { index: 0, name: 'aaaa', displayName: '01 - aaaa' },
        { index: 3, name: 'dddd', displayName: '04 - dddd' },
        { index: 9, name: 'Pikachu', displayName: '10 - Pikachu' }
      ]);
    });
    it('should return [] if state.encoder is undefined', () => {
      const state = {};
      expect(getters.availableEncoders(state)).toEqual([]);
    });
  });

  describe.skip('filteredEncoders', () => {
    it('should return array with index order', () => {
      const state = {
        3: { name: 'camera 1', username: 'user1' },
        2: { name: 'camera 1', username: 'user2' }
      };
      const expectedOrder = [
        {
          index: 2, name: 'camera 1', username: 'user2', displayName: '03 - camera 1'
        },
        {
          index: 3, name: 'camera 1', username: 'user1', displayName: '04 - camera 1'
        }
      ];
      expect(getters.filteredEncoders(state)('index', '')).toEqual(expectedOrder);
    });

    it('should return array with online order', () => {
      const state = {
        0: { name: 'camera 1', status: 'CAM_OFFLINE' },
        2: { name: 'camera 1', status: 'CAM_ACTIVE' }
      };
      const expectedOrder = [
        {
          index: 2, name: 'camera 1', status: 'CAM_ACTIVE', displayName: '03 - camera 1'
        },
      ];
      expect(getters.filteredEncoders(state)('online', '')).toEqual(expectedOrder);
    });

    it('should return array with offline order', () => {
      const state = {
        2: { name: 'camera 1', status: 'CAM_ACTIVE' },
        0: { name: 'camera 1', status: 'CAM_OFFLINE' }
      };
      const expectedOrder = [
        {
          index: 0, name: 'camera 1', status: 'CAM_OFFLINE', displayName: '01 - camera 1'
        },
      ];
      expect(getters.filteredEncoders(state)('offline', '')).toEqual(expectedOrder);
    });

    it('should return empty if encoder is undefined or searchText is empty', () => {
      const emptyState = {};
      expect(getters.filteredEncoders(emptyState)('silent')).toEqual([]);
      const state = {
        0: { name: 'evil resident' },
        3: { name: 'silent hill' },
      };
      const expectedOrder = [
        { index: 0, name: 'evil resident', displayName: '01 - evil resident' },
        { index: 3, name: 'silent hill', displayName: '04 - silent hill' }
      ];
      expect(getters.filteredEncoders(state)('index', '')).toEqual(expectedOrder);
    });
    it('should return array of object\'s name containing search text ', () => {
      const state = {
        0: { name: 'evil resident' },
        3: { name: 'silent hill' },
      };
      const expectedOrder = [
        { index: 3, name: 'silent hill', displayName: '04 - silent hill' }
      ];
      expect(getters.filteredEncoders(state)('index', 'silent')).toEqual(expectedOrder);
    });
  });
});
